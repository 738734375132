
.block {
  background-color: #00af54;
}

.even-columns {
  display: flex;
  flex-Wrap: wrap;
  gap: 1em;
  & * {
    flex-Basis: 30%;
    flex-Grow: 1;
    flex-Shrink: 1;
    margin: 0.2em;
    // [theme.breakpoints.down('sm')]: {
    //     flexBasis: '50%'
    // }
  }
}

//PAGE-SCROLLBAR
body::-webkit-scrollbar {
  width: 5px !important;             /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: orange;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: blue;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid orange;  /* creates padding around scroll thumb */
}

//HEADER
.headerMain {
  display: flex;
  flex-Direction: column;
  align-Items: flex-end;
  // [theme.breakpoints.down('sm')]: {
  justify-Content: center;
  // }
}
.headerMenu {
  display: flex;
  flex: 1;
  justify-Content: flex-end;
  align-items: flex-end;
  background-Color: white;
  margin-Right: 5em;
  @media only screen and (max-width: 400px) {
    justify-content: center;
    width: 100%;
    margin-top: 0.5em;
  }
}
.spacer {
  border-Right: 1px solid gray;
  margin: 0 10px 0 10px;
  @media only screen and (max-width: 400px) {
    margin: 0 6px 0 6px;
  }
}
