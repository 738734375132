.slick-prev{
  left: 16px;
  z-index: 10;
  &::before{
    color: #cacaca !important;
  }
}

.slick-next{
  right: 16px;
  z-index: 10;
  &::before{
    color: #cacaca !important;
  }
}

.slick-slider div {
  outline: none
}

.slick {
  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.slick-slide{
  display: none;
  float: left;
  height: auto;
  min-height: 1px;
  img{
    max-width: 100vw !important;
  }
}
