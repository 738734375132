.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@font-face {
  font-family: "DINPro";
  src: url("assets/fonts/dinpro/DINPro-Black_13933.ttf") format("truetype");
}

@font-face {
  font-family: "DINPro-Light";
  src: local("DINPro-Light_13935"),
  url("assets/fonts/dinpro/DINPro-Light_13935.ttf") format("truetype");
}

@font-face {
  font-family: "DINPro-Bold";
  src: local("DINPro-Bold_13934"),
  url("assets/fonts/dinpro/DINPro-Bold_13934.ttf") format("truetype");
}

@font-face {
  font-family: "fontawesome-webfont.woff2";
  src: local("fontawesome-webfont.woff2"),
  url("./../node_modules/font-awesome/fonts/fontawesome-webfont.woff2")
  format("woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  font-display: block;
  src: local("Roboto-Regular"),
  url("./assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Light";
  font-display: block;
  src: local("Roboto-Light"),
  url("./assets/fonts/roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  font-display: block;
  src: local("Roboto-Bold"),
  url("./assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
}

.pac-container {
  z-index: 2000;
}

::-webkit-scrollbar {
  width: 10px;             /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #a1a1a1;        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: black;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid black;  /* creates padding around scroll thumb */
}

